<template>
	<WizardBase
		:subtitle="subtitle"
		:title="title"
		:error="importerError"
		:loader-message="$t('wizard.loader.importer')"
		:loader-type="loaderType"
		:show-back="false"
		:show-skip="false"
		:show-continue="isContinueButtonVisible"
		:is-disabled="!isUrlValid"
		:current-step="currentStep"
		@back="goBackward"
		@continue="onContinue"
		@try-again="goToStepOne"
	>
		<Component
			:is="currentStepComponent"
			:value="currentStepPropertyValue"
			:type="currentStepPropertyValue"
			:wizard-websites="importedWebsites"
			:wizard-type="WIZARD_TYPE_IMPORTER"
			:has-pressed-continue="hasPressedContinue"
			:is-opening-user-site="isOpeningUserSite"
			@input="setCurrentStepPropertyValue"
			@next-step="goForward"
			@url-valid="setUrlValidity"
			@final-step-action="importWebsite"
			@select-website="selectWebsite($event)"
			@enter="onContinue"
		/>
	</WizardBase>
</template>

<script>
import { computed } from '@vue/composition-api';
import {
	mapActions,
	mapMutations,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { importWebsite } from '@/api/WebsiteImporterApi';
import baseTemplate from '@/data/BaseTemplate.json';
import i18n from '@/i18n/setup';
import WizardBase from '@/pages/wizard/WizardBase.vue';
import {
	IMPORTED_SITE_NAME,
	IMPORTED_SITE_TEMPLATE_ID,
	WIZARD_TYPE_IMPORTER,
} from '@/pages/wizard/data/constants';
import {
	DEFAULT_VALUES,
	IMPORTER_STEPS,
	PROPERTIES,
} from '@/pages/wizard/data/steps';
import { useStep } from '@/pages/wizard/use/useStep';
import { BUILDER_ROUTE } from '@/router';
import { NOTIFICATION_TYPE_RENDERLESS } from '@/store/builder/notifications';
import { useNotifications } from '@/use/useNotifications';

const IMPORTER_FAILED_ERROR = {
	title: i18n.t('wizard.selectImportedWebsite.errorTitle'),
	subtitle: i18n.t('wizard.selectImportedWebsite.errorSubtitle'),
};

export default {
	components: {
		WizardBase,
		ImporterInput: () => import('@/pages/wizard/importer/ImporterInput.vue'),
		WizardPreview: () => import('@/pages/wizard/WizardPreview.vue'),
	},
	setup() {
		const {
			stepState,
			loaderType,
			currentStepData,
			currentStep,
			currentStepComponent,
			currentStepPropertyValue,
			canGoBack,
			canSkip,
			subtitle,
			title,
			errorTitle,
			errorSubtitle,
			isNavigationFixedBottom,
			setActiveStep,
			setLoaderType,
			setCurrentStepPropertyValue,
			goBackward,
			goForward,
			skip,
		} = useStep({
			steps: IMPORTER_STEPS,
			stepState: { websiteUrl: DEFAULT_VALUES[PROPERTIES.WEBSITE_URL] },
		});
		const { notify } = useNotifications();

		const isContinueButtonVisible = computed(() => currentStepData.value.hasContinueOption);

		return {
			stepState,
			loaderType,
			currentStep,
			currentStepData,
			currentStepComponent,
			currentStepPropertyValue,
			canGoBack,
			canSkip,
			subtitle,
			title,
			errorTitle,
			errorSubtitle,
			isContinueButtonVisible,
			isNavigationFixedBottom,
			setActiveStep,
			setLoaderType,
			setCurrentStepPropertyValue,
			goBackward,
			goForward,
			skip,
			notify,
			WIZARD_TYPE_IMPORTER,
		};
	},
	data() {
		return {
			importedWebsites: [],
			importerError: null,
			isUrlValid: false,
			hasPressedContinue: false,
			isOpeningUserSite: false,
		};
	},
	mounted() {
		EventLogApi.logEvent({ eventName: 'qa_wizard.welcome_screen.importer' });
	},
	methods: {
		...mapActions([
			'saveWebsite',
			'saveWebsiteToLocalStorage',
		]),
		...mapActions('pages', ['setCurrentPage']),
		...mapMutations(['setWebsite']),
		onContinue() {
			if (this.isUrlValid) {
				this.setUrlValidity(false);
				this.hasPressedContinue = false;
				this.goForward();
			} else {
				this.hasPressedContinue = true;
			}
		},
		setUrlValidity(value) {
			this.isUrlValid = value;
		},
		goToStepOne() {
			this.importerError = null;
			this.setActiveStep(0);
		},
		async selectWebsite(index) {
			this.isOpeningUserSite = true;

			try {
				await this.saveWebsiteToLocalStorage({ websiteData: this.importedWebsites[index] });
				await this.saveWebsite();

				this.$router.push({ name: BUILDER_ROUTE });
			} catch {
				this.notify({
					message: 'Failed to open imported site. Please try again.',
					origin: 'Wizard Importer',
					submitLabel: i18n.t('common.tryAgain'),
					submitCallback: this.selectWebsite,
				});
			} finally {
				this.isOpeningUserSite = false;
			}
		},
		async importWebsite() {
			this.setLoaderType('generator');
			this.importerError = null;

			try {
				const importedWebsite = await importWebsite({
					url: this.stepState.websiteUrl,
					data: baseTemplate,
					siteName: IMPORTED_SITE_NAME,
					templateId: IMPORTED_SITE_TEMPLATE_ID,
				});

				EventLogApi.logEvent({ eventName: 'qa_wizard.website_importer.import_done' });
				this.setWebsite({
					website: importedWebsite.data,
					websiteId: importedWebsite.siteId,
				});
				this.importedWebsites = [importedWebsite.data];
			} catch {
				this.importerError = IMPORTER_FAILED_ERROR;
				EventLogApi.logEvent({ eventName: 'qa_wizard.website_importer.import_failed' });
				this.notify({ type: NOTIFICATION_TYPE_RENDERLESS });
			} finally {
				this.setLoaderType('');
			}
		},
	},
};
</script>
